import React, { lazy } from 'react';
import LoadableComponent from 'components/common/loadable-component';

/* istanbul ignore next */
const Dropdown = lazy(() => import('../../dropdown'));

const LoadableDropdown = props => (
  <LoadableComponent>
    <Dropdown {...props} />
  </LoadableComponent>
);

export default LoadableDropdown;