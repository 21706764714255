import { useState } from 'react';

export default () =>
{
  const [message, setMessage] = useState({});

  const createMessage = (text, success, permanent) =>
  {
    const number = (message.number ?? 0) + 1;
    const timeout = permanent ? 0 : 10;
    const variant = success ? 'success' : 'danger';

    setMessage(
      {
        text,
        variant,
        timeout,
        number
      }
    );
  };

  const resetMessage = () =>
  {
    createMessage(null, true);
  };

  return [message, createMessage, resetMessage];
};