import React, { lazy } from 'react';
import { useApolloClient } from 'react-apollo';
import { func } from 'prop-types';
import LoadableComponent from 'components/common/loadable-component';

/* istanbul ignore next */
const Wizard = lazy(() => import('../../../../../event-registration-wizard'));

const EventRegistrationWizard = ({ registerOpenCallback }) =>
{
  const apolloClient = useApolloClient();

  return (
    <LoadableComponent>
      <Wizard
        opened={false}
        client={apolloClient}
        registerOpenCallback={registerOpenCallback}
      />
    </LoadableComponent>
  );
};

EventRegistrationWizard.propTypes = {
  registerOpenCallback: func.isRequired
};

export default EventRegistrationWizard;