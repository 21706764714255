import React from 'react';
import { string, node } from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { fragmentTypes } from 'wa.graphql-api.schema/gen';

const RootContainer = ({ children, apolloEndpoint }) =>
{
  const fragmentMatcher = fragmentTypes ?
    new IntrospectionFragmentMatcher({
      introspectionQueryResultData: fragmentTypes
    }) :
    undefined;

  const cache = new InMemoryCache({ fragmentMatcher });
  const link = new HttpLink({
    uri: apolloEndpoint
  });
  const client = new ApolloClient({ cache, link });

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        {children}
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

RootContainer.propTypes = {
  apolloEndpoint: string.isRequired,
  children: node.isRequired
};

export default RootContainer;
