export default async (method) =>
{
  const url = (window.bonaPage_MemberPanel ?? window.parent.bonaPage_MemberPanel).send2faCodeUrl;
  const response = await fetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify({ method }),
      headers: { 'Content-Type': 'application/json' }
    });

  return response.json();
};