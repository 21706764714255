import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getFormat } from './get-format';

dayjs.extend(utc);

const DEFAULT_OPTIONS = { timeSpecified: false };

export const TIME_FORMAT = {
  H12: 'h12',
  H24: 'h24'
};

export const getTimeFormatString = timeFormat => (
  timeFormat === TIME_FORMAT.H12 ?
    'h:mm A' :
    'H:mm'
);

export const buildDateFormatter = (dateFormatValue, timeFormatValue) => (value, options = DEFAULT_OPTIONS) =>
{
  const momentFormat = getFormat(dateFormatValue);

  const format =
    options.timeSpecified ?
      `${momentFormat} [at] ${getTimeFormatString(timeFormatValue)}` :
      momentFormat;

  return dayjs.utc(value).format(format);
};

export const buildTimeFormatter = timeFormatValue => (value) =>
{
  const format = getTimeFormatString(timeFormatValue);

  return dayjs.utc(value).format(format);
};
