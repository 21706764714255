export default {
  /* eslint-disable max-len */
  TITLE: 'Verify that it\'s you',
  VERIFICATION_CODE_SENT_MESSAGE: 'A new verification code just been sent',
  ERROR_MESSAGE: 'Oops, something went wrong',
  ERROR_THROTTLING_REACHED_MESSAGE: 'Short messages limit for your region is reached. Please try out later',
  TO_MANY_REQUESTS_MESSAGE: 'Too many requests',
  WRONG_CODE_MESSAGE: 'Wrong verification code. Please try again.',
  EXPIRED_CODE_MESSAGE: 'Expired verification code',
  CODE_EXPIRATION_MESSAGE: 'Verification code is now expired. Click on the Resend verification code link to get a new code.',
  OPTED_OUT_MESSAGE: 'The number is opted out.',
  TOP_TEXT_1_EMAIL: 'A verification code was sent to your email address at ',
  TOP_TEXT_1_PHONE: 'A verification code was sent to your phone number at ',
  TOP_TEXT_2: ', which is valid for ',
  TOP_TEXT_3: ' minutes. Please provide the verification code below to proceed:',
  TIME_REMAINING_LABEL: 'Time remaining:',
  RESEND_CODE_LINK_LABEL: 'Resend verification code',
  BOTTOM_TEXT_1: 'Note: if the email message does not arrive within ',
  BOTTOM_TEXT_2: ' minutes, check your spam folder.',
  VERIFY_BUTTON_LABEL: 'Verify and proceed',
  CLOSE_BUTTON_LABEL: 'Close',
  CHANGE_METHOD_LINK_LABEL_SMS: 'Try SMS verification',
  CHANGE_METHOD_LINK_LABEL_EMAIL: 'Try email verification',
  SELECTOR_DESCRIPTION: 'Two-factor authentication protects your account from fraudulent activity by verifying you are legitimate account administrator.',
  SELECTOR_QUESTION: 'How would you like to receive your verification code?',
  SELECTOR_ITEM_EMAIL_HEADER: 'Via email',
  SELECTOR_ITEM_EMAIL_DESCRIPTION: 'Get an email verification code at ',
  SELECTOR_ITEM_SMS_HEADER: 'Via SMS',
  SELECTOR_ITEM_SMS_DESCRIPTION: 'Get a text verification code at '
  /* eslint-enable max-len */
};