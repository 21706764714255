export const MAPPING = {
  MMddyyyySlash: 'MM/DD/YYYY',
  ddMMyyyySlash: 'DD/MM/YYYY',
  dMMMyyyySpace: 'D MMM YYYY',
  ddMMyyyyDot: 'DD.MM.YYYY',
  ddMMyyyyHyphen: 'DD-MM-YYYY',
  ddMMMyyyySpace: 'DD MMM YYYY',
  ddMMMyyyyHyphen: 'DD-MMM-YYYY',
  yyyyMMddHyphen: 'YYYY-MM-DD',
  ddddMMMMddyyyy: 'dddd, MMMM DD, YYYY',
  dddMMMMddyyyy: 'ddd, MMMM DD, YYYY',
  MMMMddyyyy: 'MMMM DD, YYYY',
  ddMMMMyyyy: 'DD MMMM YYYY'
};

export const DEFAULT_FORMAT = 'MMddyyyySlash';

export const getFormat = waFormat => MAPPING[waFormat] || MAPPING[DEFAULT_FORMAT];