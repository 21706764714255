import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Challenge from './components/challenge';
import Selector from './components/selector';
import methods from './methods.js';

const TwoFactorAuth = ({ savedMethod, email, phone }) =>
{
  const [method, setMethod] = useState(savedMethod);

  const close = () =>
  {
    window.parent.document.getElementById('id2faChallenge').style.display = 'none';
    window.parent.document.getElementsByTagName('BODY')[0].style.overflow = 'auto';
  };

  useEffect(() =>
  {
    if (!window.frameElement) return;

    window.frameElement.style.height = `${window.document.body.offsetHeight + 16}px`;
    window.document.body.style.overflow = 'hidden';
  });

  if (method === methods.UNDEFINED)
  {
    return (
      <Selector setMethod={setMethod} email={email} phone={phone} close={close} />
    );
  }

  return (
    <Challenge
      method={method}
      setMethod={setMethod}
      email={email}
      phone={phone}
      close={close}
    />
  );
};

TwoFactorAuth.propTypes =
{
  savedMethod: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
};

export default TwoFactorAuth;