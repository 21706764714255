import { useState, useCallback } from 'react';
import doAuth from '../service/do-auth';
import l10n from '../../../../l10n/en';
import status from '../const/auth-status';

export default (setMessage, resetMessage, method) =>
{
  const [buisy, setBuisy] = useState(false);

  const auth = useCallback(
    async (code) =>
    {
      resetMessage();
      setBuisy(true);

      try
      {
        const data = await doAuth(code, method);

        switch (data.status)
        {
          case status.TOO_MANY_REQUESTS:
            setMessage(l10n.TO_MANY_REQUESTS_MESSAGE, false);
            return false;
          case status.WRONG_CODE:
            setMessage(l10n.WRONG_CODE_MESSAGE, false);
            return false;
          case status.EXRIRED:
            setMessage(l10n.EXPIRED_CODE_MESSAGE, false);
            return false;
          default:
            return true;
        }
      }
      catch (ex)
      {
        setMessage(l10n.ERROR_MESSAGE, false);
        return false;
      }
      finally
      {
        setBuisy(false);
      }
    },
    [setMessage, resetMessage, method]
  );

  return [auth, buisy];
};
