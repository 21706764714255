import React, { Suspense, Children } from 'react';
import { node } from 'prop-types';
import ErrorBoundary from 'components/common/error-boundary';

export const defaultFallbackTestId = 'default-fallback-test-id';
export const defaultFallback = (
  <div data-test-id={defaultFallbackTestId} />
);

const LoadableComponent = ({ fallback, children }) => (
  <ErrorBoundary>
    <Suspense fallback={fallback}>
      {Children.only(children)}
    </Suspense>
  </ErrorBoundary>
);

LoadableComponent.propTypes = {
  children: node.isRequired,
  fallback: node
};

LoadableComponent.defaultProps = {
  fallback: defaultFallback
};

export default LoadableComponent;
