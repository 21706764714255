export default async (code, method) =>
{
  const url = (window.bonaPage_MemberPanel ?? window.parent.bonaPage_MemberPanel).do2faUrl;
  const response = await fetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify({ code, method }),
      headers: { 'Content-Type': 'application/json' }
    });

  return response.json();
};