import React from 'react';
import fql from 'fraql';
import PropTypes from 'prop-types';
import { buildDateFormatter, buildTimeFormatter } from './formatters';
import DateFormatContext from './date-format-context';

const dateFormat = fql`
  fragment _ on Query {
    __typename
    dateFormat
    timeFormat
  }
`;

export const DateFormatProvider = ({ children, data }) => (
  <DateFormatContext.Provider
    value={{
      dateFormat: data.dateFormat,
      formatDate: buildDateFormatter(data.dateFormat, data.timeFormat),
      formatTime: buildTimeFormatter(data.timeFormat)
    }}
  >
    {children}
  </DateFormatContext.Provider>
);

DateFormatProvider.fragments = {
  dateFormat
};

DateFormatProvider.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    dateFormat: PropTypes.string,
    timeFormat: PropTypes.string
  }).isRequired
};