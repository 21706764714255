import React from 'react';
import { render } from 'react-dom';
import TwoFactorAuth from './src/two-factor-auth';

window.render2faChallenge = (method, email, phone) =>
{
  const node = document.createElement('div');
  document.body.appendChild(node);

  render(<TwoFactorAuth savedMethod={method} email={email} phone={phone} />, node);
};