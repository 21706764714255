import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { noop } from 'node-noop';
import prepareComponent from './prepare-component.jsx';

export default (root, treeProps) =>
{
  const mountedComponents = {};
  const unmountedHandlers = {};
  let mountHook = noop;
  let unmountHook = noop;
  let id = 0;

  const mount = (component, container, { resolvers, defaultState } = {}) => new Promise((resolve) =>
  {
    id += 1;

    mountedComponents[id] = {
      component,
      resolvers,
      defaultState,
      container,
      resolve
    };

    mountHook(id);
  });

  const unmount = (mountedId) =>
  {
    const definition = mountedComponents[mountedId];

    return new Promise((resolve) =>
    {
      if (definition)
      {
        delete mountedComponents[mountedId];

        unmountedHandlers[mountedId] = resolve;

        unmountHook(mountedId);
      }
      else
      {
        resolve(mountedId);
      }
    });
  };

  class Tree extends Component
  {
    constructor()
    {
      super();

      this.state = {
        components: mountedComponents
      };
    }

    componentDidMount()
    {
      Object.keys(mountedComponents).forEach(componentId => mountedComponents[componentId].resolve(componentId));

      mountHook = this.mountComponent;
      unmountHook = this.unmountComponent;
    }

    mountComponent = (componentId) =>
    {
      this.setState({ components: mountedComponents }, () =>
      {
        mountedComponents[componentId].resolve(componentId);
      });
    };

    unmountComponent = (componentId) =>
    {
      this.setState({ components: mountedComponents }, () =>
      {
        const resolve = unmountedHandlers[componentId];

        delete unmountedHandlers[componentId];
        resolve(componentId);
      });
    };

    render()
    {
      const { components } = this.state;
      const definitions = Object.values(components);
      const RootComponent = root;

      return (
        <RootComponent {...treeProps}>
          {
            definitions.map(
              definition => createPortal(prepareComponent(definition), definition.container)
            )
          }
        </RootComponent>
      );
    }
  }

  return {
    Tree,
    mount,
    unmount
  };
};