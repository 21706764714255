import React from 'react';
import { ApolloConsumer } from 'react-apollo';

const isValid = obj => typeof (obj) === 'object' && obj !== null;

const createComponent = ({ component: Component, resolvers, defaultState }) => (apolloClient) =>
{
  if (isValid(resolvers))
  {
    apolloClient.addResolvers(resolvers);
  }

  if (isValid(defaultState))
  {
    apolloClient.cache.writeData(defaultState);
  }

  return Component;
};

export default options => (
  <ApolloConsumer>{createComponent(options)}</ApolloConsumer>
);