import React from 'react';
import { render } from 'react-dom';
import createTree from '../tree';

export default ({ root, node: inputNode, apolloEndpoint }) =>
{
  let node = inputNode;

  if (!node)
  {
    node = document.createElement('div');
    document.body.appendChild(node);
  }

  const { Tree, mount, unmount } = createTree(root, { apolloEndpoint });

  render(<Tree />, node);

  return { mount, unmount, apolloEndpoint };
};