import { PureComponent } from 'react';
import { node } from 'prop-types';

export const errorMessage = 'Something went wrong. Please try again';

export default class ErrorBoundary extends PureComponent
{
  static propTypes = {
    children: node.isRequired
  };

  constructor(props)
  {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError()
  {
    return { hasError: true };
  }

  componentDidCatch()
  {
    window.alert(errorMessage); // eslint-disable-line no-alert
  }

  render()
  {
    const { hasError } = this.state;

    if (hasError)
    {
      return null;
    }

    const { children } = this.props;

    return children;
  }
}