import React from 'react';
import { getPageRoot } from 'components/page';
import { noop } from 'node-noop';
import EventRegistrationWizard from './components/event-registration-wizard';

export class WizardOpener
{
  constructor(apiEndpoint)
  {
    this.pageProps = { apolloEndpoint: apiEndpoint };
    this.callbacks = [];
    this.mounted = false;
    this.openArgs = null;
    this.events = {};
  }

  registerOpenCallback = (open) =>
  {
    if (typeof (open) !== 'function')
    {
      // eslint-disable-next-line no-console
      console.warn(`WizardOpener expects open callback to be a function,
       but got: ${typeof (open)}. Doing nothing.`);
      this.callbacks.push(noop);
    }
    else
    {
      this.callbacks.push(open);
    }

    if (this.openArgs)
    {
      this.openWizard(this.openArgs);
    }

    return () => { this.callbacks.pop(); };
  };

  renderWizard = () =>
  {
    if (this.mounted) { return; }

    const wizardContainer = document.createElement('div');
    document.body.appendChild(wizardContainer);
    wizardContainer.setAttribute('id', 'react-event-wizard-root');

    getPageRoot(this.pageProps).mount((
      <EventRegistrationWizard registerOpenCallback={this.registerOpenCallback} />
    ),
    wizardContainer);

    this.mounted = true;
  };

  openWizard = (args) =>
  {
    if (args && args.eventId)
    {
      this.events[args.eventId] = true;
    }

    if (!this.callbacks.length)
    {
      this.openArgs = args;
    }
    else
    {
      // TODO:Vladimir Refactor to use single data source for "opened" modal state
      this.openArgs = null;

      const index = Object.keys(this.events).length > 1 ? 0 : this.callbacks.length - 1;

      this.callbacks[index](args);
    }
  }
}