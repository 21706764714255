import createContainer from './create-container/create-container';
import RootContainer from './root-container';

const PAGE_ROOT_STATUS = {
  INITIAL: 'INITIAL',
  INITIALIZED: 'INITIALIZED'
};

export default () =>
{
  const pageState = {
    status: PAGE_ROOT_STATUS.INITIAL,
    root: null,
    apolloEndpoint: null
  };

  return ({ apolloEndpoint }) =>
  {
    if (pageState.status === PAGE_ROOT_STATUS.INITIAL)
    {
      pageState.root = createContainer({ root: RootContainer, apolloEndpoint });
      pageState.status = PAGE_ROOT_STATUS.INITIALIZED;
      pageState.apolloEndpoint = apolloEndpoint;
    }

    if (pageState.status === PAGE_ROOT_STATUS.INITIALIZED && apolloEndpoint !== pageState.apolloEndpoint)
    {
      // eslint-disable-next-line no-console
      console.error(`Trying to register ${apolloEndpoint} GraphQL endpoint, 
        but ${pageState.apolloEndpoint} already registered. Having two different endpoints is not supported yet.
      `);
    }

    return pageState.root;
  };
};