import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMessageSms } from '@fortawesome/pro-solid-svg-icons';
import l10n from '../../../l10n/en.js';
import methods from '../../../methods.js';
import './selector.scss';

const Selector = (
  {
    setMethod,
    email,
    phone,
    close
  }) =>
{
  const handleEmailClick = () => setMethod(methods.EMAIL);

  const handleSmsClick = () => setMethod(methods.SMS);

  const maxEmailLength = 9;
  const emailLower = email.toLowerCase();
  const emailCut = email.length > maxEmailLength ? `${emailLower.substring(0, maxEmailLength)}...` : emailLower;

  return (
    <div align="center">
      <Modal.Dialog>
        <Modal.Header closeButton closeLabel="" onHide={close}>
          <Modal.Title>
            {l10n.TITLE}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div align="justify">{l10n.SELECTOR_DESCRIPTION}</div>
          <div align="justify" className="h6 mt-3 mb-3">{l10n.SELECTOR_QUESTION}</div>
          <div>
            <Row>
              <Col className="pr-2">
                <Container
                  className="border rounded h-100 -wa-2fa-selector-item"
                  onClick={handleEmailClick}
                >
                  <FontAwesomeIcon icon={faEnvelope} className="text-primary w-50 h-50 mt-3" />
                  <div className="h6">{l10n.SELECTOR_ITEM_EMAIL_HEADER}</div>
                  <div className="text-secondary mb-3" align="justify">
                    {l10n.SELECTOR_ITEM_EMAIL_DESCRIPTION}
                    <strong>{emailCut}</strong>
                  </div>
                </Container>
              </Col>
              <Col className="pl-2">
                <Container
                  className="border rounded h-100 -wa-2fa-selector-item"
                  onClick={handleSmsClick}
                >
                  <FontAwesomeIcon icon={faMessageSms} className="text-primary w-50 h-50 mt-3" />
                  <div className="h6">{l10n.SELECTOR_ITEM_SMS_HEADER}</div>
                  <div className="text-secondary mb-3">
                    {l10n.SELECTOR_ITEM_SMS_DESCRIPTION}
                    <strong>{phone}</strong>
                  </div>
                </Container>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
};

Selector.propTypes =
{
  setMethod: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default Selector;