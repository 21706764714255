import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import './toast.scss';

const Toast = (
  {
    text, variant, timeout, number
  }
) =>
{
  const [visible, setVisible] = useState(true);

  useEffect(() =>
  {
    setVisible(true);

    if (!text || !timeout) return () => {};

    const timer = setInterval(() => setVisible(false), timeout * 1000);

    return () => clearInterval(timer);
  }, [text, timeout, number]);

  return (
    <div className="-wa-2fa-toast">
      {
        (text && visible)
          ? (<Alert variant={variant}>{text}</Alert>)
          : null
      }
    </div>
  );
};

Toast.propTypes =
{
  text: PropTypes.string,
  variant: PropTypes.string,
  timeout: PropTypes.number,
  number: PropTypes.number
};

Toast.defaultProps =
{
  text: null,
  variant: 'danger',
  timeout: 0,
  number: 0
};

export default Toast;