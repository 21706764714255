import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useSendCode from './hooks/use-send-code';
import useDoAuth from './hooks/use-do-auth';
import useMessages from './hooks/use-messages';
import Challenge from './challenge';
import methods from '../../../methods';
import l10n from '../../../l10n/en';

const ChallengeContainer = (
  {
    method,
    setMethod,
    email,
    phone,
    close
  }) =>
{
  const [code, setCode] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [message, setMessage, resetMessage] = useMessages();
  const [resend, sending, expirationTime, lockEndTime, hint] = useSendCode(setMessage, resetMessage, method);
  const [auth, authing] = useDoAuth(setMessage, resetMessage, method);

  const handleResend = async () => resend();

  const handleAuth = async () =>
  {
    if (!await auth(code)) return;

    close();
    window.parent.location = window.parent.bonaPage_MemberPanel.switchToAdminUrl;
  };

  const handleSetTimeRemaining = (time) =>
  {
    setTimeRemaining(time);

    if (time === 0 && expirationTime > 0)
    {
      setMessage(l10n.CODE_EXPIRATION_MESSAGE, false, true);
    }
  };

  const changeMethod = () =>
  {
    if (method === methods.EMAIL) setMethod(methods.SMS);
    else setMethod(methods.EMAIL);
  };

  return (
    <Challenge
      method={method}
      code={code}
      setCode={setCode}
      setMethod={changeMethod}
      email={email}
      phone={phone}
      close={close}
      expirationTime={expirationTime}
      timeRemaining={timeRemaining}
      setTimeRemaining={handleSetTimeRemaining}
      lockEndTime={lockEndTime}
      resend={handleResend}
      sending={sending}
      auth={handleAuth}
      authing={authing}
      messageText={message.text}
      messageVariant={message.variant}
      messageTimeout={message.timeout}
      messageNumber={message.number}
      hint={hint}
    />
  );
};

ChallengeContainer.propTypes =
{
  method: PropTypes.number.isRequired,
  setMethod: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
};

export default ChallengeContainer;