import { WizardOpener } from './src/wizard-opener.jsx';

let wizard = null;

export const connectToGadget = () =>
{
  window.createBatchEventRegistrationWizard = (apiEndpoint) =>
  {
    if (!wizard)
    {
      wizard = new WizardOpener(apiEndpoint);

      window.BonaPage.addPageStateHandler(window.BonaPage.PAGE_PARSED, wizard.renderWizard);
    }

    return wizard;
  };
};
