import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './shade.scss';

const Shade = () => (
  <div className="-wa-2fa-shade">
    <Spinner animation="border" variant="primary" className="-wa-2fa-spinner" />
  </div>
);

export default Shade;