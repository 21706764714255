import { useState, useEffect, useCallback } from 'react';
import sendCode from '../service/send-code';
import status from '../const/send-status';
import l10n from '../../../../l10n/en';

export default (setMessage, resetMessage, method) =>
{
  const [expirationTime, setExpirationTime] = useState(0);
  const [lockEndTime, setLockEndTime] = useState(0);
  const [buisy, setBuisy] = useState(false);
  const [hint, setHint] = useState(null);

  const send = useCallback(
    async (resend) =>
    {
      resetMessage();
      setBuisy(true);

      try
      {
        const data = await sendCode(method);

        switch (data.Status)
        {
          case status.OPTED_OUT:
            setMessage(l10n.OPTED_OUT_MESSAGE, false);
            return;
          case status.TOO_MANY_REQUESTS:
            setMessage(l10n.TO_MANY_REQUESTS_MESSAGE, false);
            return;
          case status.EMAIL_SENDING_ERROR:
            setMessage(l10n.ERROR_MESSAGE, false);
            return;
          case status.THROTTLING_REACHED_ERROR:
            setMessage(l10n.ERROR_THROTTLING_REACHED_MESSAGE, false);
            return;
          default:
        }

        setHint(data.Code ? ` (${data.Code})` : '');
        setExpirationTime(Date.now() + data.ExpirationTime * 1000);
        setLockEndTime((data.ExpirationTime - data.LockTime) * 1000);

        if (resend) setMessage(l10n.VERIFICATION_CODE_SENT_MESSAGE, true);
      }
      catch (ex)
      {
        setMessage(l10n.ERROR_MESSAGE, false);
      }
      finally
      {
        setBuisy(false);
      }
    },
    [setMessage, resetMessage, method]
  );

  const resend = useCallback(async () => send(true), [send]);

  useEffect(() =>
  {
    const sendInternal = async () => { await send(false); };
    sendInternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);

  return [
    resend,
    buisy,
    expirationTime,
    lockEndTime,
    hint
  ];
};